import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { UserSession } from '../models/usersession';

@Injectable()

export class UserSessionService {

  session = new UserSession();
  localStorageSessionKey: string;
  localStorageSessionKeyLabel: string;
  constructor() {
    this.localStorageSessionKey = 'Schneider-' + environment.apiBaseUrl + '-AuthData';
    this.localStorageSessionKeyLabel = 'Schneider-' + environment.apiBaseUrl + '-labelData';
  }

  create(session) {// jshint ignore:line
    this.setLocalStorageProperties(session);
  };
  destroy() {// jshint ignore:line
    this.setLocalStorageProperties(new UserSession());
  };

  load() { // jshint ignore:line
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData;
  };

  authToken() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? '' : JSON.parse(jsonData).authToken;
  }

  userId(): number {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? 0 : +JSON.parse(jsonData).userId;
  }
  

  userFullName() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? '' : JSON.parse(jsonData).userFullName;
  }

 
  setLocalStorageProperties(session) {// jshint ignore:line
    localStorage.setItem(this.localStorageSessionKey, JSON.stringify(session));
  };

  setLocalStorageLabel(session) {// jshint ignore:line
    localStorage.setItem(this.localStorageSessionKeyLabel, JSON.stringify(session));
  };


} 
