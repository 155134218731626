import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import 'rxjs/add/operator/map';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
declare const ActiveXObject: (type: string) => void;

@Injectable()
export class DataService {

  constructor(private http: HttpClient) {
  }

  private baseUrl = environment.apiBaseUrl;
  private cache: any = {};
  getData(route, refresh) {
    if (this.dataForRouteIsCached(route, refresh)) {
      return of(this.cache[route]);
    } else { //no cached data or refresh requested
      return this.http.get<any>(this.baseUrl + route).map(response => {
        this.cache[route] = response;
        return response;
      });
    }
  };


  getDataWithParams(route, params, refresh) {
    if (this.dataForRouteIsCached(route, refresh)) {
      return of(this.cache[route]);
    } else { //no cached data or refresh requested
      return this.http.get<any>(this.baseUrl + route, { params: params }).map(response => {
        this.cache[route] = response;
        return response;
      });
    }
  };
  /*   getDate(route,refresh){
      return this.http.get<any>(this.baseUrl + route,refresh);
    } */
  getRecord(route) {
    return this.http.get<any>(this.baseUrl + route);
  };

  put(route, data) {
    return this.http.put<any>(this.baseUrl + route, data);
  };


  getRecordWithParams(route, params) {
    return this.http.get<any>(this.baseUrl + route, { params: params });
  };

  post(route, data) {
    return this.http.post<any>(this.baseUrl + route, data);
  }


  delete(route) {
    return this.http.delete(this.baseUrl + route).map(response => {
      return response;
    });
  }

  deleteAll(route, data) {
    var param = { 'Content-Type': 'application/json' }
    return this.http.delete(this.baseUrl + route, { headers: param, observe: data }).map(response => {
      return response;
    });
  }

  getReport(route) {
    return this.http.get(this.baseUrl + route, { responseType: 'blob' });
  };

  getExternalData(route) {
    return this.http.get<any>(route).map(response => {
      return response;
    });
  };

  dataForRouteIsCached(route, refresh) {
    return this.cache[route] && (refresh === false || refresh == undefined);
  }

  clearCache() {
    this.cache = {};
  }

  clearRouteCache(route) {
    this.cache[route] = null;
  }
  makeGETRequestWithoutLoading(url: string) {
    let promise = new Promise((resolve, reject) => {
      var xhr;
      // if (window.XMLHttpRequest) {
      //   // code for modern browsers
      xhr = new XMLHttpRequest();
      // } else {
      //   // code for old IE browsers
      //   xhr = new ActiveXObject("Microsoft.XMLHTTP");
      // }
      xhr.timeout = environment.xhrTimeout;
      xhr.open('GET', this.baseUrl + url, true);
      xhr.onload = function () {
        if (this.status >= 200 && this.status < 300) {
          resolve(xhr.response);
        } else {
          reject({
            status: this.status,
            statusText: xhr.statusText
          });
        }
      };
      xhr.ontimeout =  function () {
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      };
      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      };
      xhr.send();
    });
    return promise;
  }
}
