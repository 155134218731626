import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()

export class NavigationService {
    constructor(private router: Router) {

    }
    goToLogin() {
        this.router.navigate(['/login']);
    }
    goToLanding() {
        this.router.navigate(['/landing']);
    }

    goToChangePassword() {
        this.router.navigate(['/changepassword']);
    }

    goToViewRemarks(id) {
        this.router.navigate(['/auth/meterremarks/'+id]);
    }
    goToViewJobs(id) {
        this.router.navigate(['/auth/viewjobs/'+id]);
    }
    goToManualReading() {
        this.router.navigate(['/auth/manualreading/']);
    }
    goToAreas() {
        this.router.navigate(['/area']);
    }
    goToJobs() {
        this.router.navigate(['/job']);
    }
    goToViewmeter(id) {
        this.router.navigate(['/auth/viewmeter/'+id]);
    }
    goToJobManagement() {
        this.router.navigate(['/auth/jobmanagement']);
    }
    goToJobAdd() {
        this.router.navigate(['/auth/jobspage']);
    }
    goToUserAdd() {
        this.router.navigate(['/auth/useradd']);
    }
    goToEditUser(id) {
        this.router.navigate(['/auth/useredit/'+id]);
    }
    goToViewUser(id) {
        this.router.navigate(['/auth/useradd/'+id]);
    }
    goToUserManagement() {
        this.router.navigate(['/auth/usermanagement']);
    }
    goToHome() {
        this.router.navigate(['/auth/dashboard']);
    }

    goToArea(id, isEdit) {
        this.router.navigate(['/area/' +  isEdit + '/' +id]);
    }
    goToJob(id, isEdit) {
        this.router.navigate(['/job/' +  isEdit + '/' +id]);
    }
    goToTrade(id, isEdit) {
        this.router.navigate(['/trade/' +  isEdit + '/' +id]);
    }
    goToTraining(id, isEdit) {
        this.router.navigate(['/training/' +  isEdit + '/' +id]);
    }
    goToHelp(id, isEdit) {
        this.router.navigate(['/helprequest/' +  isEdit + '/' +id]);
    }

    goToAllocations() {
        this.router.navigate(['/allocation']);
    }

    goToAllocation(id, isEdit) {
        this.router.navigate(['/allocation/' +  isEdit + '/' +id]);
    }

    goToOrganisations() {
        this.router.navigate(['/organisation']);
    }
    goToViewSearch(identityNumber) {
        this.router.navigate(['/search/' + identityNumber]);
    }
    goToSearch() {
        this.router.navigate(['/search']);
    }
    goToOrganisation(id, isEdit) {
        this.router.navigate(['/organisation/' +  isEdit + '/' +id]);
    }

    goToMasjids() {
        this.router.navigate(['/masjid']);
    }

    goToMasjid(id, isEdit) {
        this.router.navigate(['/masjid/' +  isEdit + '/' +id]);
    }
    goToMusalees() {
        this.router.navigate(['/musalee']);
    }

    goToMusalee(id, isEdit) {
        this.router.navigate(['/musalee/' +  isEdit + '/' +id]);
    }
    goToNotices() {
        this.router.navigate(['/notice']);
    }

    goToNotice(id, isEdit) {
        this.router.navigate(['/notice/' +  isEdit + '/' +id]);
    }
    goToBeneficiaries() {
        this.router.navigate(['/beneficiary']);
    }

    goToBeneficiarie(id, isEdit) {
        this.router.navigate(['/beneficiary/' +  isEdit + '/' +id]);
    }

    goToAllocationEntries() {
        this.router.navigate(['/allocationentry']);
    }

    goToAllocationEntrie(id, isEdit) {
        this.router.navigate(['/allocationentry/' +  isEdit + '/' +id]);
    }

    goToUsers() {
        this.router.navigate(['/user']);
    }

    goToUser(id, isEdit) {
        this.router.navigate(['/user/' +  isEdit + '/' +id]);
    }


}
