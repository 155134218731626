// export const environment = {
//   production: false,
//   firebase: {
//     apiKey: "AIzaSyDYv02aLpqUgtJIP9CbT31mOHpu0a77xLM",
//     authDomain: "cdk-admin.firebaseapp.com",
//     databaseURL: "https://cdk-admin.firebaseio.com",
//     projectId: "cdk-admin",
//     storageBucket: "cdk-admin.appspot.com",
//     messagingSenderId: "422055031001",
//     appId: "1:422055031001:web:261c3f03c6dbe830df8ec8",
//     measurementId: "G-NYGXB63DCC"
//   },
//   mailApi: '/assets/list.json'
// };
export const environment = {
 apiBaseUrl: 'https://dlms-staging.xenovex.com/stagingapi',   
 production: true,
 xhrTimeout: 4000,
 pingInterval: 5000
};
