import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UserSessionService } from '../services/usersession.service';
import { DataService } from '../services/data.service';
import { UserSession } from '../models';
import { AlertService } from '../services/alertservice';
import jwt_decode from 'jwt-decode';
import * as _ from 'lodash';

import 'rxjs/add/operator/map';


declare var require: any;
var timezone = require('../../assets/timezones.json');

@Injectable()
export class AuthenticationService {

  private baseUrl = environment.apiBaseUrl;
  timeZones: any[];
  sessionData = new UserSession();

  constructor(
    private alertService: AlertService,
    private http: HttpClient,
    private dataService: DataService,
    private sessionService: UserSessionService,
  ) {
    // this.getTimeZones();
  }

  login(email: string, password: string, param: boolean) {
    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      });

    // const timeZone = this.getBrowserTimeZone();
    // const data = 'username=' + username + '&password=' + password +'&param=' + param+ '&timezone=' + timeZone;
    const data = {
      username: email,
      password: password,
      // param: param,
      // timezone: timeZone,
    };

    return this.http.post<any>(this.baseUrl + '/api/token', data, { headers: headers })
      .map(user => {
        if (user && user.access_token) {
          // debugger;
          this.clearCachedMenu();
          const decodedToken = jwt_decode(user.access_token);
          this.sessionData.authToken = user.access_token;
          this.sessionData.userId = decodedToken["user.id"];
          this.sessionData.userFullName = decodedToken["user.fullname"];
          this.sessionService.create(this.sessionData);
          // console.log(decodedToken,'LoginService');
        }
        return user;
      });
  }

  forgotPassword(data){
      return this.dataService.post('/api/user/forgotpassword', data);
  }
  verifyOTP(data){
    return this.dataService.post('/api/user/verifyOtp', data);
  }
  changePass(data){
    return this.dataService.post('/api/user/changePwd', data);
  }
  isAuthenticated() {
    return !!this.sessionService.authToken();
  }

  // isPageAccessAvailable(url, pageTitle,) {
  //   return true;
  // }


//   getTimeZones() {
//     this.timeZones = timezone.timeZone;
//   }

//   getBrowserTimeZone(): string {
//     var zoneName = momenttz.tz.guess();
//     var timezone = momenttz.tz(zoneName).zoneAbbr();
//     var filterZone = this.timeZones.find(i => i.abbr === timezone);
//     if (filterZone) {
//       return filterZone.value;
//     }
//     return '';
//   }

  logOut() {
    localStorage.clear();
    this.clearCachedMenu();
    this.sessionService.destroy();
  }

  clearCachedMenu() {
    this.dataService.clearCache();
  }
}