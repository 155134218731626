import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import { Router } from '@angular/router';
import { NavigationService } from '../../../services';
import { JobService } from '../../../services/job.service';
import { UserSessionService } from '../../../services/usersession.service';
import Swal from 'sweetalert2/dist/sweetalert2.js'; 
import { ViewjobsComponent } from '../viewjobs/viewjobs.component';

@Component({
  selector: 'app-jobmanagement',
  templateUrl: './jobmanagement.component.html',
  styleUrls: ['./jobmanagement.component.scss']
})
export class JobmanagementComponent implements OnInit {
  displayedColumns: string[] = ['ViewUpdate','JobName', 'strAssignedOn', 'jobStatusTypeName','syncStatus',
                                'jobAssigned','Meter','Synced','NotSynced','ErrorData'];
  // dataSource = this.list;
  jobList:any=[];
  newList:any=[];
  constructor(private router: Router,private navigationService:NavigationService,
    private jobService: JobService,private userService: UserSessionService) { }  

  pageSize = 10;
  currentPage = 0;
  totalSize = 0; 
  pageEvent: PageEvent;
  dataSource = new MatTableDataSource(this.jobList);
  
  ngOnInit(): void {
   
    this.Getlist();
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(ViewjobsComponent) child;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  myInputMessage:string ="I am the parent comppnent"  
  // let values = JSON.parse(localStorage.getItem("userId"));
  Getlist(){
    var UserId=this.userService.userId();
    const data={
      Id:"",
      StartDate:"",
      EndDate:"",
      JobStatusType:""
    };

    this.jobService.getJobList(data).subscribe(res => {
      if(res){        
        this.jobList=res;
        console.log(this.jobList,"Sync Status");
        for(var [i,item] of this.jobList.entries()){
          if(item.syncStatusType==1){
            item.syncStatusTypeName = 'Synced';
          }
          else if(item.syncStatusType==2){
            item.syncStatusTypeName = 'Not Synced';
          }

          if(item.jobStatusType==1){
            item.jobStatusTypeName = 'New';
          }
          else if(item.jobStatusType==2){
            item.jobStatusTypeName = 'In Progress';
          }
      }
        for(var [i,value] of this.jobList.entries()){
          // if(value.jobStatusTypeName=="New" && value.syncStatusTypeName=="Synched")
          // {
          //   value.syncStatusTypeName='Partially Synched';
          // }
          // this.newList.push(value);
        }
     
      // console.log(res,"result");
        this.dataSource = new MatTableDataSource(this.jobList.reverse());
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

      }
    });
  }
  goBack(){
    this.navigationService.goToHome();
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  DeleteJobs(id){

    Swal.fire({  
      title: 'Are you sure want to remove?',  
      text: 'You will not be able to recover this!',  
      icon: 'warning',  
      showCancelButton: true,  
      confirmButtonText: 'Yes, delete it!',  
      cancelButtonText: 'No, keep it'  
    }).then((result) => {  
      if (result.value) {  
        this.jobService.deleteJob(id).subscribe(res => {
      console.log(res);
      this.Getlist();
      Swal.fire(  
        'Deleted!',  
        'Your job has been deleted.',  
        'success'  
       )  
      });
      } else if (result.dismiss === Swal.DismissReason.cancel) {  
        Swal.fire(  
          'Cancelled',  
          // 'Your imaginary file is safe :)',  
          // 'error'  
        )  
      }  
    }) 
  }
  AddJobs(){
    this.router.navigate(['auth/jobspage']);
  }
  getBackground(value){
    if(value=='Pending'){
      return 'red';
    }
    else if(value=='Completed'){
      return 'green';
    }
    else if(value=='In process'){
      return 'yellow';
    }
  }
  ViewJobs(value,jobName){
    localStorage.setItem('JobCode', jobName);
    this.router.navigate(['auth/viewjobs/'+value]);
  }
}
