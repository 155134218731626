<div class="toolbar-user-container">
	<button mat-button (click)="isOpen = !isOpen" class="toolbar-user-btn" [class.open]="isOpen" [ngStyle.xs]="{'min-width': '70px'}">
  		<span fxLayout="row" fxLayoutAlign="start center">
    		<img class="avatar" [src]="currentUser?.photoURL || 'assets/images/avatars/noavatar.png'">
    		<span class="name" fxHide fxShow.gt-xs>{{currentUser?.currentUserName || Hari}}</span>
    		<mat-icon class="icon" fxHide fxShow.gt-xs>keyboard_arrow_down</mat-icon>
  		</span>
  	</button>

	  <!-- <i title="Log out" (click)="onLogout()" class="material-icons click">logout</i> -->
  	<div class="dropdown mat-elevation-z1" [class.open]="isOpen">
    	<div class="content">
      		<mat-nav-list>
      			<!-- <mat-list-item>
      				<a matLine >Profile</a>
				    <button mat-icon-button>
				       <mat-icon>account_circle</mat-icon>
				    </button>
      			</mat-list-item>
      			<mat-list-item>
      				<a matLine >Settings</a>
				    <button mat-icon-button>
				       <mat-icon>settings</mat-icon>
				    </button>
      			</mat-list-item>
      			<mat-list-item>
      				<a matLine >Help</a>
				    <button mat-icon-button>
				       <mat-icon>help</mat-icon>
				    </button>
      			</mat-list-item> -->

      			<mat-divider></mat-divider>
      			<mat-list-item (click)="profileCheck()">
      				<a matLine >Profile</a>
				    <button mat-icon-button>
				       <mat-icon>supervised_user_circle</mat-icon>
				    </button>
      			</mat-list-item>
				  <mat-list-item (click)="onLogout()">
					<a matLine >Logout</a>
				  <button mat-icon-button>
					 <mat-icon>exit_to_app</mat-icon>
				  </button>
				</mat-list-item>
      		</mat-nav-list>
    	</div>
  	</div>
</div>