import { Component } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  query,
} from '@angular/animations'
import { TranslateService } from '@ngx-translate/core';
import { SpinnerComponent } from './shared/component/spinnercomponent/spinner.component';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  

})
export class AppComponent {
  title = 'Schneider';
  spinnerComponent = SpinnerComponent;
  getRouteAnimation(outlet) {
      
      return outlet.activatedRouteData.animation
  }
  constructor(
    public translate: TranslateService
  ) {
    translate.addLangs(['en', 'nl']);
    translate.setDefaultLang('en');
  }
}
