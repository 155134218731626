import { Component, Input, OnInit,ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JobService, NavigationService } from '../../../services';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
@Component({
  selector: 'app-viewjobs',
  templateUrl: './viewjobs.component.html',
  styleUrls: ['./viewjobs.component.scss']
})
export class ViewjobsComponent implements OnInit {
  displayedColumns: string[] = ['View','serialNo','meterCode','consumerNumber', 'phaseTypeName','syncStatus', 
                                'consumerAddress','lastReading','currentReading'];
pageSize = 10;
currentPage = 0;
totalSize = 0; 
pageEvent: PageEvent;
List: any=[];
dataSource = new MatTableDataSource(this.List);
@ViewChild(MatPaginator) paginator: MatPaginator;
@ViewChild(MatSort) sort: MatSort;
@ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  routeId;
  Address;
  ConsumerNo;
  serialNo;
  currentReading;
  showMyContainer: boolean = false;
  getJobCode:any;


  constructor(private jobService:JobService
    ,private navigationService:NavigationService,private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.routeId = this.route.snapshot.paramMap.get('id');
    this.getDetails();
  }

  getDetails(){
    var getData = this.routeId;
    var index = getData.indexOf( "-" ); 
    var getLength = this.routeId.length;
    var spliceData = getData.slice(index+1,getLength);
    this.jobService.getViewJob(spliceData,true).subscribe(result => {
    this.List=result;
    console.log(this.List,"List");
      for(var [i,item] of this.List.entries()){
        if(item.syncStatusType==1){
          item.syncStatusTypeName = 'Synced';
        }
        else if(item.syncStatusType==2){
          item.syncStatusTypeName = 'Not Synced';
        }
    }
      this.dataSource = new MatTableDataSource(this.List);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.getJobCode=localStorage.getItem('JobCode');
    });
  }
  goBack(){
    this.navigationService.goToJobManagement();
  }
  ViewMeter(id){
    this.navigationService.goToViewmeter(id);
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
