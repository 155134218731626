<div class="grid">
  <div class="row mb-2">
    <div class="col-lg-9 col-md-6  col-xs-12 col-sm-4">
      <button type="submit" class="btn btn-secondary buttonJob " (click)="goBack()">
        <i title="Back" class="material-icons backButton" >arrow_back_ios</i>  
          Go Back                      
      </button>  
    </div>
    <div class="col-lg-3 col-md-6 col-xs-12  col-sm-4 text-align">
      <button type="submit" class="btn btn-success buttonJob " (click)="AddJobs()">
        <span class="material-icons">
          add
        </span>
        {{'AddJob' | translate}}
      </button>
    </div>
  </div>
  <hr  width="100%" size="20" color="green" class="mt-0">
  <div class="table-responsive mat-elevation-z8 table-container" style="margin-bottom: 40px;">
      <div class="">
        <h4 class="ml-3 mt-2 m-0">{{'JobManagement' | translate}} - <strong>{{'Joblist' | translate}}</strong></h4>
      </div>
  <!-- <div class="row">
    <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12"> -->
      <!-- <div class="input-group">
                <input type="text" class="form-control" placeholder="Search" id="txtSearch"/>
                <div class="input-group-btn">
                  <button class="btn btn-success" type="submit">
                    Search
                  </button>
                </div>
            </div> -->
      <div class="row m-0">
      <mat-form-field class="col-lg-4 col-md-4 col-xs-4 col-sm-4 mt-2">
          <input matInput #searchInput (keyup)="applyFilter($event)" placeholder="{{'Filter' | translate}}">
      </mat-form-field>
      <div class="col-lg-7"> 

      </div>
      <div class="col-lg-1 floatButton">
        <button style="float: right;" type="submit" class="btn btn-success buttonJob mt-2" (click)="Getlist()">
          <span class="material-icons">
            refresh
          </span>
        </button>
      </div>
    </div>
      <!-- <input #searchInput (keyup)="applyFilter($event)" class="filterInput" type="text" autocomplete="doNotAutoComplete"
        placeholder="Filter" /> -->
    <!-- </div>
  </div> -->
  <!-- <hr width="98%" size="40" color="green"> -->
  <!-- <br> -->
  <!-- <cdk-virtual-scroll-viewport class="example-viewport" [itemSize]="11"> -->
  
    <mat-table  [dataSource]="dataSource" matSort matSortStart="asc" class="setminWidMobile">
      <!-- <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef> # </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
      </ng-container> -->

      <ng-container matColumnDef="ViewUpdate">
        <mat-header-cell *matHeaderCellDef>{{ 'Action' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <!-- <i class="material-icons icon_color">edit</i> -->
          <i title="View Job" (click)="ViewJobs(element.id,element.jobName)" class="material-icons click icon_color">
            visibility</i>
          <i title="View Job" (click)="DeleteJobs(element.id)" class="material-icons click icon_color">
            delete</i>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="JobName">
        <mat-header-cell *matHeaderCellDef mat-sort-header="jobName">{{ 'Job Code' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.jobName}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="strAssignedOn">
        <mat-header-cell *matHeaderCellDef mat-sort-header="strAssignedOn">{{ 'Upload Date' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.strAssignedOn}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="jobStatusTypeName">
        <mat-header-cell *matHeaderCellDef mat-sort-header="jobStatusTypeName">{{ 'Job Status' | translate}} </mat-header-cell>
        <mat-cell  *matCellDef="let element">
          <label [ngClass]="{'badge-danger': element.jobStatusTypeName === 'New',
          'badge-success': element.jobStatusTypeName === 'Completed',
          'badge-warning': element.jobStatusTypeName === 'In Progress'}" 
          class="badge">
              {{element.jobStatusTypeName}} 
          </label>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="syncStatus">
        <mat-header-cell *matHeaderCellDef mat-sort-header="syncStatusTypeName">{{ 'Sync Status' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <label [ngClass]="{'badge-danger': element.syncStatusTypeName === 'Not Synced',
          'badge-success': element.syncStatusTypeName === 'Synced',
          'badge-warning': element.syncStatusTypeName === 'Partially Synced'}" 
          class="badge">
                {{element.syncStatusTypeName}} 
          </label>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="jobAssigned">
        <mat-header-cell *matHeaderCellDef mat-sort-header="operatorName">{{ 'Job Assigned To' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.operatorName}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Meter">
        <mat-header-cell *matHeaderCellDef mat-sort-header="totalMeters">{{ 'No. of Meters' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.totalMeters}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="Synced">
        <mat-header-cell *matHeaderCellDef mat-sort-header="totalSyncMeters">{{ 'Meters Synced' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.totalSyncMeters}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="NotSynced">
        <mat-header-cell *matHeaderCellDef mat-sort-header="totalNonSyncMeters">{{ 'Meters Not Synced' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.totalNonSyncMeters}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="ErrorData">
        <mat-header-cell *matHeaderCellDef mat-sort-header="totalErrorMeters">{{ 'In Error' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.totalErrorMeters}}</mat-cell>
      </ng-container>

      <!-- <ng-container matColumnDef="Jobassignby">
              <th mat-header-cell *matHeaderCellDef> Job Assign By</th>
              <td mat-cell *matCellDef="let element"> {{element.Jobassignby}} </td>
            </ng-container> -->

      <!-- <ng-container matColumnDef="view">
              <th mat-header-cell *matHeaderCellDef> View </th>
              <td mat-cell *matCellDef="let element">
                <i class="material-icons">visibility</i>
              </td>
            </ng-container> -->

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      <!-- <mat-header-row style="background: #aacbed;box-shadow: 0px 1px 7px 0px rgb(18, 1, 41);"
            *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row style=" box-shadow: 0px 1px 7px 0px rgb(18, 1, 41);" *matRowDef="let row; columns: displayedColumns;">
          </mat-row> -->
    </mat-table>
    <!-- </cdk-virtual-scroll-viewport> -->
    <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
      </mat-paginator> -->
    <mat-paginator #paginator [length]="dataSource!=null && dataSource.filteredData.length" [pageIndex]="0" [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 100]"
      showFirstLastButtons>
    </mat-paginator>
    <div class="noRecordFound" *ngIf="dataSource === null || dataSource.filteredData.length === 0"><h4>No records found</h4></div>
  </div>
</div>