import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { DataService } from "./data.service";
import { UserSessionService } from './usersession.service';


@Injectable()
export class UserService {
  private baseUrl = environment.apiBaseUrl;

  constructor(
    private dataService: DataService,
    private userSessionService:UserSessionService,
    private http: HttpClient) { }
   
    getUserList(refresh: Boolean) {
      return this.dataService.getData('/api/user/userlist', refresh);
    }
    getUserOperators(refresh: Boolean) {
      return this.dataService.getData('/api/user/operators', refresh);
    }
    getrole(refresh: Boolean) {
      return this.dataService.getData('/api/user/roleType', refresh);
    }
    viewUserData(refresh: Boolean,id) {
      return this.dataService.getData('/api/user/'+id ,refresh);
    }
    viewMeterRemarks(refresh: Boolean,id) {
      return this.dataService.getData('/api/meter/manualreading/'+id ,refresh);
    }
    viewManualReading(refresh: Boolean,startTs,endTs) {
      return this.dataService.getData('/api/meter/manualreading/'+startTs+'/'+endTs ,refresh);
    }
}
