import { Component, OnInit, Input, HostListener, ElementRef } from '@angular/core';
import { UserSessionService } from '../../services';
import { AuthenticationService } from '../../services/authentication.services';
import { NavigationService } from '../../services/navigation.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'cdk-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
	isOpen: boolean = false;
	userId:any;
  	//currentUser = null;
  	Hari;
  	

  	@Input() currentUser = null;
  	@HostListener('document:click', ['$event', '$event.target'])
  	onClick(event: MouseEvent, targetElement: HTMLElement) {
    	if (!targetElement) {
     		return;
    	}

    	const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    	if (!clickedInside) {
      		this.isOpen = false;
    	}
  	} 	
	     
  	constructor(private elementRef: ElementRef,
		private authService: AuthenticationService, private userService: UserService,
		private usersessionService : UserSessionService,private navigationService: NavigationService) { }

	
  	ngOnInit() {
		this.userId=this.usersessionService.userId();
  	}
	  onLogout() {
		this.authService.logOut();
		this.navigationService.goToLogin();
	}
	  profileCheck() {
		this.navigationService.goToViewUser(this.userId);
	}
}
