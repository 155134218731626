import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToolbarHelpers } from './toolbar.helpers';
import { UserSessionService } from '../../services/usersession.service';

@Component({
  selector: 'cdk-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
	
  @Input() sidenav;
	@Input() sidebar;
	@Input() drawer;
	@Input() matDrawerShow;
  
	searchOpen: boolean = false;
    toolbarHelpers = ToolbarHelpers;
	userFullName: any;
  	constructor(public translate: TranslateService, private userService: UserSessionService ) { 
		translate.addLangs(['en', 'nl']);
		translate.setDefaultLang('en');
		this.userFullName = this.userService.userFullName();
	  }

  	ngOnInit() {
			this.toolbarHelpers.currentUser.currentUserName = this.userFullName;
  	}

	  switchLang(lang: string) {
		this.translate.use(lang);
	  }

}
