import { Injectable } from '@angular/core';
import { DataService } from "./data.service";

@Injectable()
export class DashboardService {
    constructor(
        private dataService: DataService) { }

    getJobQuickStatus(refresh: Boolean) {
        return this.dataService.getData('/api/job/count',refresh);
        }

}