<!-- <div class="bg"> -->
    <div class="row mb-2">
        <div class="col-lg-12">
          <button type="submit" class="btn btn-secondary buttonJob " (click)="goBack()">
            <i title="Back" class="material-icons backButton" >arrow_back_ios</i>  
              Go Back                      
          </button> 
        </div>
    </div>
    <hr width="100%" class="mt-0"
    size="20" color="green">   
      <div class="table-responsive  mat-elevation-z8 table-container" style="margin-bottom: 40px;">
        <h4 class="mt-2 ml-3">{{'Job-Code' | translate }} : <strong>{{getJobCode}}</strong></h4> 
        <div class="row m-0">
          <mat-form-field class="col-lg-4 col-md-4 col-xs-4 col-sm-4 mt-2">
              <input matInput #searchInput (keyup)="applyFilter($event)" placeholder="{{'Filter' | translate}}">
          </mat-form-field>
          <div class="col-lg-7">

          </div>
          <div class="col-lg-1 floatButton">
            <button style="float: right;" type="submit" class="btn btn-success buttonJob mt-2" (click)="getDetails()">
              <span class="material-icons">
                refresh
              </span>
            </button>
          </div>
        </div>
        <mat-table [dataSource]="dataSource" matSort matSortStart="asc" class="setminWidMobile">
          <!-- <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef> # </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
          </ng-container> -->

          <ng-container matColumnDef="View">
            <mat-header-cell *matHeaderCellDef>{{'Action' | translate}} </mat-header-cell>
            <mat-cell class="inline-icon" *matCellDef="let element">                
              <i class="material-icons click icon_color inline-icon" 
              title="View Meter" (click)="ViewMeter(element.meterId)">
                visibility</i>
            </mat-cell>
          </ng-container>
    
          <ng-container matColumnDef="serialNo">
            <mat-header-cell *matHeaderCellDef mat-sort-header="serialNo">{{'Serial No' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.serialNo}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="meterCode">
            <mat-header-cell *matHeaderCellDef mat-sort-header="meterCode">Meter Code</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.meterCode}} </mat-cell>
          </ng-container>
          
          <ng-container matColumnDef="consumerNumber">
            <mat-header-cell *matHeaderCellDef mat-sort-header="consumerNumber">Consumer No.</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.consumerNo}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="phaseTypeName">
            <mat-header-cell *matHeaderCellDef mat-sort-header="phaseTypeName">{{'Phase Type' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.phaseTypeName}} 
                <img *ngIf="element.phaseTypeName == '1 PH'" src="../../assets/icons/1 phase.png" width="32" height="32" alt="Null">
                <img *ngIf="element.phaseTypeName == '3 PH'" src="../../assets/icons/3 phase.png" width="32" height="32" alt="Null">
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="syncStatus">
            <mat-header-cell *matHeaderCellDef mat-sort-header="syncStatusTypeName">{{'Sync Status' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <label [ngClass]="{'badge-danger': element.syncStatusTypeName === 'Not Synced',
              'badge-success': element.syncStatusTypeName === 'Synced',
              'badge-warning': element.syncStatusTypeName === 'Partially Synchronized'}" 
              class="badge">
                    {{element.syncStatusTypeName}} 
              </label>
            </mat-cell>
          </ng-container>
    
          <ng-container matColumnDef="consumerAddress">
            <mat-header-cell *matHeaderCellDef mat-sort-header="consumerAddress">{{'Consumer Address' | translate}}</mat-header-cell>
            <mat-cell  *matCellDef="let element">
              {{element.consumerAddress}} </mat-cell>
          </ng-container>
    
          <ng-container matColumnDef="lastReading">
            <mat-header-cell *matHeaderCellDef mat-sort-header="lastReading">Previous Reading</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.lastReading}} </mat-cell>
          </ng-container>
          
          <ng-container matColumnDef="currentReading">
            <mat-header-cell *matHeaderCellDef mat-sort-header="currentReading">Current Reading</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.currentReading}} </mat-cell>
          </ng-container>
          <!-- <ng-container matColumnDef="lastReading">
            <mat-header-cell *matHeaderCellDef> Previous Reading</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.lastReading}}</mat-cell>
          </ng-container> -->
    
          <!-- <ng-container matColumnDef="unitsConsumed">
            <mat-header-cell *matHeaderCellDef> Units Consumed</mat-header-cell>
            <mat-cell *matCellDef="let element" class="kwH">{{element.currentReading - element.lastReading}} kWh</mat-cell>
          </ng-container> -->
    
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        
        </mat-table>
      
        <mat-paginator #paginator [length]="dataSource!=null && dataSource.filteredData.length" [pageIndex]="0" [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 100]"
          showFirstLastButtons>
        </mat-paginator>
        <div class="noRecordFound" *ngIf="dataSource === null || dataSource.filteredData.length === 0"><h4>No records found</h4></div>
      </div>
<!-- </div> -->