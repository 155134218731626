import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { DataService } from "./data.service";
import { UserSessionService } from './usersession.service';


@Injectable()
export class JobService {
  private baseUrl = environment.apiBaseUrl;
  getEventRoute = '/api/user';

  constructor(
    private dataService: DataService,
    private userSessionService:UserSessionService,
    private http: HttpClient) { }
    saveJob(data) {
      return this.dataService.post('/api/job', data);
    }
    uploadJob(data) {
      return this.dataService.post('/api/user/upload', data);
    }
    getJobList(data) {
      // return this.dataService.getData('/api/job/getlist', refresh);
      return this.http.get(this.baseUrl + '/api/job/alljobs',{params:data})
      .map(map => {
        if (map) {
          
        }
        return map;
      });
    }
    getJob(id, refresh: Boolean) {
      return this.dataService.getData('/api/job/' + id, refresh);
    }
    getJobOperator(data) {
     
    // const token = 'Bearer '+this.userSessionService.authToken();
    // const headers_object = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //    'Authorization': token,
    // });


      return this.http.get(this.baseUrl + '/api/job/operatorjobs',{params:data})
      .map(map => {
        if (map) {
          
        }
        return map;
      });
    }

    deleteJob(id) {
      return this.dataService.delete('/api/job/'+ id);
    }
    save(result: any) {
      return this.dataService.post('/api/user', result).map(response => {
          this.dataService.clearRouteCache(this.getEventRoute);
          return response;
      });
  }
    getViewJob(id, refresh: Boolean) {
      return this.dataService.getData('/api/meter/meterbyjob/'+id, refresh);
    }
    getViewMeter(meterid,jobid ,refresh: Boolean) {
      // return this.dataService.getData('/api/meter/frames/'+1+'/'+80, refresh);
      return this.dataService.getData('/api/meter/frames/'+jobid+'/'+meterid, refresh);
    }
    getAssignToList(refresh: Boolean) {
      return this.dataService.getData('/api/user/operators/', refresh);
    }
    getAssign(refresh: Boolean) {
      return this.dataService.getData('/api/user/operatorslookup/', refresh);
    }
}
