import { Injectable } from '@angular/core';
import { DataService } from "./data.service";


@Injectable()
export class UtilityService {

  constructor(
    private dataService: DataService) { }

  getGenderLookup(refresh: Boolean) {
    return this.dataService.getData('/api/lookup/gendertype', refresh);
  }
  getRoleLookup(refresh: Boolean) {
    return this.dataService.getData('/api/lookup/roletype', refresh);
  }
  getMarriageStatusLookup(refresh: Boolean) {
    return this.dataService.getData('/api/lookup/marriagestatustype', refresh);
  }
  getStatebenefitsLookup(refresh: Boolean) {
    return this.dataService.getData('/api/lookup/statebenefitstype', refresh);
  }
  getBeneficiaryStatusTypeLookup(refresh: Boolean) {
    return this.dataService.getData('/api/lookup/beneficiarystatustype', refresh);
  }
  getBenefitTypeLookup(refresh: Boolean) {
    return this.dataService.getData('/api/lookup/benefittype', refresh);
  }
  getPageList(refresh: Boolean) {
    return this.dataService.getData('/api/user/getpagelist', refresh);
  }
  // getMenuList(refresh: Boolean) {
  //   return this.dataService.getData('/api/user/getmenulist', refresh);
  // }



}
